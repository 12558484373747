.App {
  /* background-color: #0c1c2c; */
  background-color: #0c1c2c;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
}


.App-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-toolbar-logo>img {
  width: 45px;
  margin-left: 20;
}

.App-toolbar-logo {
  margin-left: 10px;
}

.App-toolbar-text {
  color: #C69749;
  margin-left: 10px;
  font-size: 30px;
}


.App-link {
  color: #61dafb;
}

.App-google-link {
  width: 150px;
  /* you can use % */
  height: auto;
  margin-top: 10px;
}

.App-logo {
  width: 250px;
  height: auto;
  margin-left: 30px;
}

.App-footer {
  font-size: 11px;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.App-footer>p {
  font-size: 11px;
  color: white;
}

.App-footer>a {
  color: #C69749;
  font-size: 19px;
  margin: 15px;
}

.App-footer>a:visited {
  color: #C69749;
}

.App-footer>a:hover {
  color: #C69749;
}

.App-footer>a:active {
  color: #C69749;
}

.App-footer>a:focus {
  color: #C69749;
}


.App-copyright>p {
  font-size: 9px;
}

.App-copyright>a {
  color: white;
  font-size: 9px;
}

.App-copyright>a:visited {
  color: white;
  /* Set the color for visited links */
}

.App-copyright>a:hover {
  color: white;
}

.App-copyright>a:active {
  color: white;
}

.App-copyright>a:focus {
  color: white;
}





/* ------------------------------------------------- */
/* ------------------------------------------------- */
/* --------------------WEB------------------------- */
/* ------------------------------------------------- */
/* ------------------------------------------------- */

@media (min-width: 768px) {
  .App-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .App-content-text {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .App-content-text > p {
    text-align: center;
  }

  .App-content-image {
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  .App-toolbar {
    width: 100%;
    display: flex;
    align-items: center;
  }
}

/* ------------------------------------------------- */
/* ------------------------------------------------- */
/* --------------------MOBILE----------------------- */
/* ------------------------------------------------- */
/* ------------------------------------------------- */


@media (max-width: 768px) {
  .App {
    /* background-color: #0c1c2c; */
    background-color: #0c1c2c;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .App-content {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap-reverse;
    padding: 5px;
  }

  .App-toolbar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0px;
  }

  .App-toolbar-logo {
    margin-left: 10px;
  }

  .App-content-text > h3 {
    text-align: center;
  }

  .App-content-image {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  .App-logo {
    width: 150px;
    height: auto;
    margin-left: 30px;
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}